
import promotions from '~/mixins/common/pages/promotions'
export default {
    name: 'BigPromotionCardTheme3',
    mixins: [promotions],
    props: {
        type: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        src: {
            type: String,
            default: '',
        },
        item: {
            type: Object,
            default: () => {},
        },
    },
}
