import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=f8f9c48c&scoped=true"
import script from "./LoginModal.vue?vue&type=script&lang=js"
export * from "./LoginModal.vue?vue&type=script&lang=js"
import style0 from "./LoginModal.vue?vue&type=style&index=0&id=f8f9c48c&prod&scoped=true&lang=css"
import style1 from "./LoginModal.vue?vue&type=style&index=1&id=f8f9c48c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f9c48c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsPhoneInput: require('/app/components/Commons/PhoneInput/index.vue').default})
