
import fingerprint from '~/mixins/fingerprint'
import interRegister from '~/mixins/pages/register/interRegister'

export default {
    name: 'RegisterModal',
    mixins: [fingerprint, interRegister],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isConditional: false,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
    },
    async mounted() {
        const countryCode = this.$cookie.get('country_code')
        await this.$store.dispatch('banks/banks')
        await this.onOperationSetting(this.$cookie.get('currency'))
        this.form.reg_fingerprint = await this.visitorId()
        this.form = {
            ...this.form,
            country_code: countryCode,
        }
        this.countryChange(countryCode)
        const globalReg = this.$cookie?.get('global_reg')
        this.globalReg = globalReg
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$store.commit('themes/settings/setDisplayRegisterModal', false)
            this.$emit('onCloseModal')
            this.$emit('close')
        },
        register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    this.onRegister()
                }
            } else {
                this.isConditional = true
                this.$toast.error(
                    this.tr(this.FRONTEND_ERROR_CODES.pleaseCheckCondition)
                )
            }
        },
    },
}
